body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

a,a:hover,a:active,a:focus {
  text-decoration: none;
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #004981;
}

.bg-home {
  background-image: url(assets/images/banner_bg.jpg);
}

.bg-flights {
  background-image: url(assets/images/flights-bg.jpg);
}

.bg-cars {
  background-image: url(assets/images/cars-bg.jpg);
}

.bg-hotels {
  background-image: url(assets/images/hotels-bg.jpg);
}

.bg-cruises {
  background-image: url(assets/images/cruises-bg.jpg);
}

.shadowed{
  text-shadow: 1px 2px 0px rgba(0,0,0,0.7);
  letter-spacing: 0.5px;
}
#topTabMenu .menuItem a {
  color:white;
}
#topTabMenu .menuItem {
  background-color: #0c2831;
}

#topTabMenu .menuItem:hover {
  background-color: #244a53;
}

#topTabMenu .menuItem .active {
  background-color: #ed7633;
}

#topTabMenu .menuItem .active::after {
  content : '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #ed7633;
  bottom:-15px;
  left: calc(50% - 10px);
}

#topHeader .nav-link {
  color: #000000;
}

#topHeader .nav-link.active,#topHeader .nav-link:hover {
  color: #ed7633;
}


body .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #ed7633;
  background-color: #ed7633;
}

body .p-button {

  background-color: #ed7633;
  border: 1px solid #ed7633;
}

body .searchBtn {
  background-color: #fcd833;
  border: 1px solid #fcd833;
  color: black;
  font-size: 22px;
  font-weight: bolder;
}

.main-box {
  max-width: 700px;
  border:1px solid white;
  margin: auto;
  background-color: rgba(0,38,81,.6);
}

.p-calendar input {
  width: calc( 100%  - 2.357em);
}

.upTo70-backup {
  background-image: url(assets/images/upTo70.png);
  width: 120px;
  height: 108px;
  margin-right: 10px;
  margin-left: -130px;
}

.mobile-feature-list {
  display: none;
}


@media all and (max-width: 767px){
  body .p-datepicker table {
    font-size: 11px;
    margin: .857em 0 0;
  }

  .mobile-feature-list ul {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .desktop-feature-list {
    display: none;
  }

  .mobile-feature-list {
    display: block;
  }

  .form-body {
    margin-top: -20px;
  }

}


@media all and (min-width: 768px){
  .p-autocomplete .p-autocomplete-panel {
    width: 400px!important;
  }
  .form-container {
    height: 100%;
  }
}

@media all and (max-width: 991px){

  /*Offer page*/
  .desk-ad .caption h1 {
    font-size: 48px!important;
  }
  .desk-ad .caption h2 {
    font-size: 25px!important;
  }
  /*offer page*/

}


/* loader */
.loader {
  z-index: 9999;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  height: 100%;
}
.lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  display: block;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader end */

/* offer page */
.desk-ad {
  background-image: url(assets/images/offer-desk-bg.png);
  background-size: cover;
  background-position: center;
  color: #014993;
  font-weight: bold;
  font-style: italic;
}

.desk-ad .left-box-bg{
  background-image: url(assets/images/splash-left-col.png);
  background-position: center;
  background-size: cover;
}

.desk-ad .caption {
  background-image: url(assets/images/splash-part.png);
  background-position: center;
  background-size: cover;
}

.desk-ad .caption h1 {
  font-size: 60px;
}

.desk-ad .phoneBox {
  border: 2px dashed green;
  color: green;
}

.search-info h1 span.dates {
  font-size: 25px;
}


.mob-ad {
  border: 2px dashed #5eb855;
  color: #5eb855;
  font-weight: bold;
  border-radius: 5px;
}

.mob-ad .search-info {
  background-color: #5eb855;
}

.mob-ad .caption h1 {
  background-image: url(assets/images/splash-call_only_bg-mobile.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: darkgreen;
  font-size: 2.2rem;
}

.mob-ad .caption h3 {
  color:darkgreen;
}

.mob-ad .callDetails {
  background-image: url(assets/images/mob_ad_call_bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 102px;
}

.mob-ad .callDetails .phoneBox {
  margin-left: 80px;
}

.mob-ad .callDetails .phoneBox h1 {
  margin-left: 20px;
  font-size: 25px;
  font-weight: bold;
}

.mob-ad .callDetails .phoneBox h6 {
  font-weight: bold;
}

.callAdContainer, .callAdContainer input, .callAdContainer button {
  cursor: pointer!important;
}








ul.feature-list li {
  color: #5eb855;
  /* set color of list item text */
  list-style: none;
  margin: 0 0 16px 1.1225em;
  /* Give the bullet room on the left hand side */
  padding: 0;
  position: relative;
}

ul.feature-list li::before {
  color: #5eb855;
  /* color of bullet or square */
  content: '✓';
  /* Unicode of character to precede the list item */
  display: inline-block;
  font-size: 1em;
  /* use em or % */
  left: -1.1225em;
  /* use em, line up bullet flush with left hand side */
  position: absolute;
  /* Set the bullet positioned absolutely top left */
  top: 0em;
  /* use em or % */
}

ul.feature-list li span {
  color: red;
}

.color-red {
  color: red;
}

.box-border {
  box-sizing: border-box;
}




@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  5% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"
  }

  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
  }
}

@keyframes  jumper {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  5% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"
  }

  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
  }
}

.opacity-40 {
  opacity: 0.4;
}

.container-box {
  margin-bottom: 90px !important;
}

.subscription-modal-header {
  position: relative;
  left: 12px;

}

.subscription-modal-header div {
  font-size: 20px;
}

.form-container {
  max-height: 1000px;
  transition-duration: 0.2s;
}

.form-expanded {
  max-height: 0;
  transition-duration: 0.2s;
  overflow: hidden;

}

.modal-title {
  width: 100%;
}


.footer-call-box {
  border-top: 2px dashed #5eb855;
  background-color: white;
  padding-right: 11px;
  padding-bottom: 11px;
  padding-top: 10px;
  bottom: 0;
  height: 65px;
  z-index: 900;
}

.call-btn {
  position: relative;
  height: 45px;
  width: 45px;
}
.call-btn.call-btn-alone {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.call-btn:before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: #5eb855;
  border-radius: 50%;
  animation: jumper 1.8s 0s ease-out infinite;
  -webkit-animation: jumper 1.8s 0s ease-out infinite;
  height: 45px;
  width: 45px;
  z-index: -1;
  position: absolute;
}

.call-btn .phone-icon {
  background: #28a745;
  color: #fff;
  z-index: 999;
  padding: 0.8em 0em 0.8em;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.call-text span {
  color: #5eb855;
}
.MuiInputBase-formControl {
  height: 100%;
}
.contact-us-btn {
  font-size: 18px;
  padding: 0 3rem;
}
