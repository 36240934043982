.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-inner {
  padding: 5px 0;
  background: white;
}

.copyright-container {
  float: left;
  margin-top: 7px;
}

.footer-menu {
  float: right;
}

.footer-menu ul {
  list-style: none;
  margin: 0;
  border: 0;
  padding: 0;
  line-height: 1.2;
  border-radius: 0;
  background: none;
}

.footer-menu ul li {
  display: inline-block;
  margin-right: 15px;
}

footer .footer-menu ul li a {
  color: #263238 !important;
  display: block;
  padding: 8px 0;
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.2;
  transition: 200ms ease all;
}
